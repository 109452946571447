* {
    box-sizing: border-box;
    margin: 0.02%;
    padding: 0;
    background-color: rgba(16, 17, 36, 255);
    color: whitesmoke;
}

.coin-app {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 64px;
    color: whitesmoke;
}

.coin-serch {
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.coin-text {
    margin-bottom: 32px;
    text-align: center;
}

.coin-input {
    padding-left: 16px;
    width: 300px;
    height: 50px;
    border-radius: 10px;
}